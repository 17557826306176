<template>
	<!-- -----------------------------------------------
          Start Footer
    ----------------------------------------------- -->
	<v-footer id="footer" class="footer px-4">
		<v-container>
			<v-row class="py-6 py-sm-7">
				<!-- -----------------------------------------------
          First Column
        ----------------------------------------------- -->
				<v-col cols="12" md="3" sm="6" class="px-xs-0">
					<h4 class="font-weight-regular font-18">Address</h4>
					<p class="mt-5 text-muted">Puerto Princesa, Palawan, Philippines</p>
				</v-col>
				<!-- -----------------------------------------------
          Second Column
        ----------------------------------------------- -->
				<v-col cols="12" md="3" sm="6" class="px-xs-0">
					<h4 class="font-weight-regular font-18">Phone</h4>
					<p class="mt-5 text-muted">Mobile : <a href="tel:+639632160905" aria-label="Contact by phone">+63 963 2160905</a></p>
				</v-col>
				<!-- -----------------------------------------------
          Third Column
        ----------------------------------------------- -->
				<v-col cols="12" md="3" sm="6" class="px-xs-0">
					<h4 class="font-weight-regular font-18">Email</h4>
					<p class="mt-5 text-muted">
						<a class="dark-link" href="mailto:admin@diskubrepalawan.com" aria-label="Email us">admin@diskubrepalawan.com</a>
					</p>
				</v-col>
				<!-- -----------------------------------------------
          Fourth Column
        ----------------------------------------------- -->
				<v-col cols="12" md="3" sm="6" class="px-xs-0">
					<h4 class="font-weight-regular font-18">Social</h4>
					<div class="social-icons mt-5">
						<a href="https://www.facebook.com/diskubretravel" aria-label="Visit our  Facebook Page">
							<i class="mdi mdi-facebook"></i>
						</a>
						<a href="https://api.whatsapp.com/send?phone=639632160905" aria-label="Send us a WhatsApp Message">
							<i class="mdi mdi-whatsapp"></i>
						</a>
						<a href="https://m.me/diskubretravel">
							<i class="mdi mdi-facebook-messenger" aria-label="Send us message on Messenger by Facebook"></i>
						</a>
					</div>
				</v-col>
			</v-row>
			<div class="footer-bottom-bar mt-sm-5 mt-0 font-14 ml-sm-0 ml-n3">
				<div class="d-block d-sm-flex align-center">
					<p class="text-muted mb-sm-0 mb-3">
						All Rights Reserved by
						<a href="https://diskubrepalawan.com" aria-label="Official website link" class="link">diskubrepalawan.com</a>
					</p>
				</div>
			</div>
		</v-container>
	</v-footer>
	<!-- -----------------------------------------------
          End Footer
    ----------------------------------------------- -->
</template>
